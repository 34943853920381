import React from "react"
import { RichText } from "prismic-reactjs"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
export default function TextContent({ title, text }) {
  return (
    <section className="section-spacer">
      <div className="container">
        <div
          className="text-center mb-10"
          css={css`
            > * {
              margin-top: 0;
            }
          `}
        >
          {" "}
          {RichText.render(title)}
        </div>
        <div
          css={css`
            a {
              ${tw`text-black font-normal underline`}
            }
          `}
        >
          {RichText.render(text)}
        </div>
      </div>
    </section>
  )
}
