import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import get from "lodash.get"
import { css, Global } from "@emotion/core"
import tw from "tailwind.macro"
import FbIcon from "../images/facebook.png"
import WaIcon from "../images/whatsapp.png"
import InterSocca from "../images/intersocca.jpg"
import Image from "gatsby-image"
import has from "lodash.has"
export default function Layout({ children }) {
  return (
    <>
      <Global
        styles={css`
          .section-spacer {
            ${tw`py-8 lg:py-16`}
          }
        `}
      ></Global>
      <StaticQuery
        query={graphql`
          {
            prismic {
              allGlobals {
                edges {
                  node {
                    logo
                    facebook_link
                    whatsapp_link
                    footer_links {
                      page {
                        ... on PRISMIC_Page {
                          _linkType
                          _meta {
                            uid
                          }
                        }
                        ... on PRISMIC__ExternalLink {
                          _linkType
                          url
                        }
                      }
                      label
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const footerLinks = get(
            data,
            "prismic.allGlobals.edges[0].node.footer_links"
          )
          const logo = get(data, "prismic.allGlobals.edges[0].node.logo")
          const facebook_link = get(
            data,
            "prismic.allGlobals.edges[0].node.facebook_link"
          )
          const whatsapp_link = get(
            data,
            "prismic.allGlobals.edges[0].node.whatsapp_link"
          )

          return (
            <div>
              <nav className="flex justify-between py-5">
                <divc className="w-32"></divc>
                <div className="flex-shrink-0">
                  <Link to="/">
                    {" "}
                    <img className="w-40 lg:w-56" src={logo.url} />
                  </Link>
                </div>

                <div className=" w-32 z-50 bg-white right-0 top-0 p-3 flex justify-end ">
                  <a href={facebook_link} target="_blank">
                    <img className="w-6 lg:w-8 h-6 lg:h-8 " src={FbIcon} />
                  </a>
                  <a href={whatsapp_link} target="_blank">
                    <img className="w-6 lg:w-8 h-6 lg:h-8  ml-3" src={WaIcon} />
                  </a>
                </div>
              </nav>
              {children}
              <footer>
                <div className="text-center text-sm opacity-75">
                  Copyright © 2020 mamma SPORTS - All rights reserved
                </div>
                <div
                  css={css`
                    ${tw`text-center pt-2 pb-6`}
                    a {
                      ${tw`text-black font-normal `}
                      &:not(:last-of-type):after {
                        content: "/";
                        ${tw`inline-block mx-2`}
                      }
                    }
                  `}
                >
                  {footerLinks &&
                    footerLinks.map((link, i) => {
                      switch (link.page.__typename) {
                        case "PRISMIC_Page":
                          return (
                            <Link key={i} to={`/${link.page._meta.uid}/`}>
                              {link.label}
                            </Link>
                          )
                        case "PRISMIC__ExternalLink":
                          return (
                            <a key={i} href={link.page.url}>
                              {link.label}
                            </a>
                          )
                        default:
                          return null
                      }
                    })}
                </div>
              </footer>
            </div>
          )
        }}
      />
    </>
  )
}
