import React from "react"
import { RichText } from "prismic-reactjs"
import get from "lodash.get"
import Image from "gatsby-image"
export default function Partners({ section }) {
  return (
    <section className="section-spacer">
      <div className="container text-center ">
        {" "}
        {RichText.render(get(section, "primary.title"))}
        <div className="row mx-auto lg:max-w-4xl text-2xl flex items-center justify-center flex-wrap">
          {section.fields.map(({ link, logos, logoFluid }, i, { length }) => {
            return (
              <div className="col  w-1/3" key={i}>
                <a
                  href={link}
                  target="_blank
                "
                >
                  {logoFluid ? (
                    <Image className="lg:mx-3" fluid={logoFluid} />
                  ) : (
                    <img className="lg:mx-3" src={logos.url} />
                  )}
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
