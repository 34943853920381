import React from "react"
import { graphql } from "gatsby"
import has from "lodash.has"
import get from "lodash.get"

import Hero from "../components/Hero"
import Services from "../components/Services"
import Partners from "../components/Partners"
import Contact from "../components/Contact"
import Layout from "../components/Layout"
import TextContent from "../components/TextContent"
const Page = ({ data }) => (
  <Layout>
    {has(data, "prismic.page.body") &&
      get(data, "prismic.page.body").map((section, i) => {
        switch (section.type) {
          case "hero":
            return (
              <>
                <Hero section={section} />
              </>
            )
          case "services":
            return <Services section={section} />
          case "partners":
            return <Partners section={section} />
          case "kontakt_form":
            return <Contact {...section.primary} />
          case "text_content":
            return <TextContent {...section.primary} />
          default:
            return <pre>{JSON.stringify(section, null, 4)}</pre>
        }
      })}
  </Layout>
)

export const query = graphql`
  query($uid: String!) {
    prismic {
      page(lang: "de-de", uid: $uid) {
        body {
          ... on PRISMIC_PageBodyHero {
            type
            label
            primary {
              text
              image
              imageSharp {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyServices {
            type
            label
            fields {
              service
            }
            primary {
              title
            }
          }
          ... on PRISMIC_PageBodyPartners {
            type
            label
            fields {
              logos
              logoFluid
              link
            }
            primary {
              title
            }
          }
          ... on PRISMIC_PageBodyKontakt_form {
            type
            label
            primary {
              button_label
              error_message
              subtitle
              success_message
              title
            }
          }
          ... on PRISMIC_PageBodyText_content {
            type
            label
            primary {
              text
              title
            }
          }
        }
      }
    }
  }
`
Page.query = query
export default Page
