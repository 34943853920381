import React from "react"
import { RichText } from "prismic-reactjs"
import get from "lodash.get"
import Image from "gatsby-image"
export default function Hero({ section }) {
  return (
    <div>
      <div className="container text-center section-space">
        {" "}
        {RichText.render(get(section, "primary.text"))}
        <div className="lg:max-w-4xl mx-auto">
          {get(section, "primary.imageSharp.childImageSharp.fluid") && (
            <Image
              fluid={get(section, "primary.imageSharp.childImageSharp.fluid")}
            />
          )}
        </div>
      </div>
    </div>
  )
}
