import React, { useState } from "react"
import { RichText } from "prismic-reactjs"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
import { useFormik } from "formik"
import * as Yup from "yup"
import { MdCheck } from "react-icons/md"
export default function Contact({
  button_label = "",
  error_message = [],
  subtitle = [],
  success_message = [],
  title = [],
}) {
  const {
    values,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    errors,
    handleBlur,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      telefonnummer: "",
      nachricht: "",
      tos: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(RichText.asText(error_message)),
      email: Yup.string()
        .email("Ungültige E-Mail")
        .required(RichText.asText(error_message)),
      nachricht: Yup.string().required(RichText.asText(error_message)),
      tos: Yup.boolean(true).oneOf([true], RichText.asText(error_message)),
    }),
    onSubmit: (values) => {
      // fetch("/", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
      //   body: encode({ "form-name": "contact", ...values }),
      // })
      //   .then(() => setSubmitted(true))
      //   .catch((error) => alert(error))
      alert(values)
      resetForm()
    },
  })
  const [submitted, setSubmitted] = useState(false)

  return (
    <section className="section-spacer">
      <div className="container max-w-xl">
        <div
          className="text-center  "
          css={css`
            h1 {
              ${tw`mb-0`}
            }
            h3 {
              ${tw`text-2xl mt-2`}
            }
          `}
        >
          {" "}
          {RichText.render(title)}
          {RichText.render(subtitle)}
        </div>
        {submitted && (
          <div className="text-green-500 text-center">
            {RichText.render(success_message)}
          </div>
        )}
        {!submitted && (
          <form
            name="contact"
            data-netlify
            onSubmit={handleSubmit}
            css={css`
              .block {
                ${tw`border border-gray-200 bg-gray-100 text-gray  w-full mb-4 flex flex-nowrap `}
              }
              .error {
                ${tw`mb-4 -mt-3 text-xs pl-3 text-red-500`}
              }
              label {
                ${tw` p-3  flex-shrink-0 whitespace-no-wrap`}
              }
              input:not([type="checkbox"]),
              textarea {
                ${tw`bg-transparent  p-3    outline-none w-full  `}
              }
              textarea {
                min-height: 150px;
              }
            `}
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="block">
              <label htmlFor="name">Name*</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
            </div>
            {touched.name && errors.name && (
              <div className="error">{errors.name}</div>
            )}
            <div className="block ">
              <label htmlFor="email">E-Mail*</label>
              <input
                type="text"
                id="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
            </div>
            {touched.email && errors.email && (
              <div className="error">{errors.email}</div>
            )}
            <div className="block">
              <label htmlFor="telefonnummer">Telefonnummer</label>
              <input
                type="text"
                id="telefonnummer"
                name="telefonnummer"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.telefonnummer}
              />
            </div>
            <div className="block">
              <label htmlFor="nachricht">Nachricht*</label>
              <textarea
                type="text"
                id="nachricht"
                name="nachricht"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nachricht}
              />
            </div>
            {touched.nachricht && errors.nachricht && (
              <div className="error">{errors.nachricht}</div>
            )}
            <label
              css={css`
                padding: 0 !important;
                display: flex;
                ${tw`items-center mb-4`}
                .text {
                  font-size: 12px;
                  white-space: pre-wrap;
                }
              `}
            >
              <input
                type="checkbox"
                required
                id="tos"
                name="tos"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.tos}
                className=" "
                css={css`
                  width: 0;
                  height: 0;
                  opacity: 0;

                  & + span {
                    flex-shrink: 0;
                    ${tw`w-3 h-3  bg-gray-100 border-gray-200 border flex items-center justify-center mr-3`}
                  }
                  & + span svg {
                    display: none;
                  }
                  &:checked + span svg {
                    display: block;
                  }
                `}
              />{" "}
              <span>
                {" "}
                <MdCheck />
              </span>
              <div className="text">
                {" "}
                Mit Absenden der Nachricht haben Sie unsere
                Datenschutzbestimmungen gelesen und akzeptiert.
              </div>
            </label>
            {touched.tos && errors.tos && (
              <div className="error">{errors.tos}</div>
            )}
            <div className="text-center">
              <button
                className={`bg-orange rounded-full text-white py-3 lg:py-4 px-8 font-bold text-xl focus:outline-none`}
              >
                {button_label}
              </button>
            </div>
          </form>
        )}
      </div>
    </section>
  )
}
const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
