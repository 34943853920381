import React from "react"
import { RichText } from "prismic-reactjs"
import get from "lodash.get"
import Image from "gatsby-image"
export default function Services({ section }) {
  return (
    <section className="section-spacer">
      <div className="container text-center  ">
        {" "}
        {RichText.render(get(section, "primary.title"))}
        <div className="mx-auto lg:max-w-2xl text-lg lg:text-2xl flex items-center justify-center flex-wrap">
          {section.fields.map(({ service }, i, { length }) => {
            return (
              <>
                <span key={service}>{service}</span>
                {i + 1 < length && <span className="text-orange px-2">|</span>}
              </>
            )
          })}
        </div>
      </div>
    </section>
  )
}
